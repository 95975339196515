import React, { Component } from "react";
import { Link } from "gatsby";

// Footer Koji light logo
import footer_logo from "../../static/img/general/logo-light.svg";

class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <div className="container">
            <img alt="footer-logo" className="footer-logo" src={footer_logo} />
            <div className="car-statement" />
            <div className="footer-group">
              <div className="collaborate-links">
                <p>Want to collaborate with us?</p>
                <p>
                  Get in touch via <a href="mailto:hello@koji.capital">hello@koji.capital</a> or{" "}
                  <a href="https://twitter.com/koji_capital" rel="noreferrer" target="_blank">
                    @koji_capital on Twitter
                  </a>
                </p>
              </div>
              <div className="contact-links">
                <Link to="/contact">
                  <span>Contact Us</span>
                  <button aria-label="Contact Us" className="circle-btn sml" />
                </Link>
              </div>
            </div>
            <div className="site-links">
              <div className="link-box">
                <p className="title">Sitemap</p>
                <Link to="/portfolio">Portfolio</Link>
                {/* <Link to="/read">Read</Link> */}
                {/* <Link to="/listen">Listen</Link> */}
                <Link to="/contact">Contact</Link>
                <Link to="/disclaimer">Disclaimer</Link>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="link-box">
                <p className="title">Socials</p>
                <a
                  href="https://linkedin.com/company/koji-capital/about/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
                <a href="https://twitter.com/koji_capital" target="_blank" rel="noreferrer">
                  Twitter
                </a>
                <a href="https://discord.gg/sTya2MY7qu" target="_blank" rel="noreferrer">
                  Discord
                </a>
                <a
                  href="https://www.youtube.com/channel/UCUrLZv1iBoyX78kFHYNsMZA"
                  target="_blank"
                  rel="noreferrer"
                >
                  YouTube
                </a>
                <a
                  href="https://mirror.xyz/0x57e2C1Fe15C9df256431090327CDb01FbEE7A979"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mirror
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
