import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import logo_light from '../../static/img/general/logo-light.svg'
import logo_dark from '../../static/img/general/logo-dark.svg'
import close_btn from '../../static/img/general/close.svg'
import linkedin_icon from '../../static/img/social-icons/linkedin.svg'
import twitter_icon from '../../static/img/social-icons/twitter.svg'

const routes = [
  { to: '/about', label: 'About' },
  { to: '/portfolio', label: 'Portfolio' },
  {
    to: 'https://mirror.xyz/0x57e2C1Fe15C9df256431090327CDb01FbEE7A979',
    label: 'Read',
  },
  // { to: "/team", label: "Team" },
  // { to: "/listen", label: "Listen" },
  { to: '/contact', label: 'Contact' },
]

class Navbar extends Component {
  getActiveLink() {
    const currentPath = window.location.pathname
    const navLinks = document.querySelectorAll('.nav-link')
    navLinks.forEach((navLink) => {
      if (navLink.getAttribute('href') === currentPath) {
        navLink.classList.add('active')
      } else {
        navLink.classList.remove('active')
      }
    })
  }
  links() {
    return routes.map(({ to, label, i }) => {
      return (
        <>
          <li className="nav-item" key={i}>
            <Link className="nav-link" to={to}>
              {label}
            </Link>
          </li>
        </>
      )
    })
  }

  componentDidMount() {
    this.getActiveLink()
  }
  render() {
    return (
      <nav className={this.props.type}>
        <div className="container">
          <Link className="logo nav-link" to="/">
            <img alt="Koji logo white" className="light" src={logo_light} />
            <img alt="Koji logo brown" className="dark" src={logo_dark} />
          </Link>
          <div className="right-nav">
            <ul>{this.links()}</ul>
          </div>
          <span className="menu-toggle">
            <button className="nav-btn">
              <span className="bar" />
              <span className="bar" />
              <span className="bar" />
            </button>
          </span>
        </div>
        <menu className="mobile-nav">
          <span className="menu-text">Menu</span>
          <button className="close-btn">
            <img alt="Close button" src={close_btn} />
          </button>
          <div className="mobile-nav__items">
            <ul>
              {this.links()}
              <li className="nav-item" />
            </ul>
            <div className="mobile-socials">
              <a
                className="social-link"
                target="_blank"
                rel="noreferrer"
                href="https://linkedin.com/company/koji-capital/about/"
              >
                <picture>
                  <img alt="LinkedIn icon" src={linkedin_icon} />
                </picture>
              </a>
              <a
                className="social-link"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/koji_capital"
              >
                <picture>
                  <img alt="Twitter icon" src={twitter_icon} />
                </picture>
              </a>
            </div>
          </div>
        </menu>
      </nav>
    )
  }
}

Navbar.propTypes = {
  type: PropTypes.string,
}

export default Navbar
